@use "./mixins.scss" as *;
@use "./variables.scss" as *;

@mixin h1 {
  font-weight: $light;
  font-size: 60px;
  line-height: 90%; /* 54px */
  letter-spacing: -1.8px;

  @include mq-max($md) {
    font-size: 36px;
    line-height: 90%; /* 32.4px */
    letter-spacing: -1.08px;
  }
}

@mixin h2 {
  font-weight: $light;
  font-size: 48px;
  line-height: 90%; /* 43.2px */
  letter-spacing: -0.48px;
}

@mixin h3 {
  font-weight: $regular;
  font-size: 36px;
  line-height: 90%; /* 32.4px */
  letter-spacing: -1.44px;
}

@mixin h4 {
  font-weight: $semi-bold;
  font-size: 24px;
  line-height: 110%; /* 26.4px */
  letter-spacing: -0.48px;
}

@mixin display-1 {
  font-weight: 275;
  font-size: 120px;
  line-height: 90%; /* 108px */
  letter-spacing: -8.4px;
}

@mixin display-2 {
  font-weight: 275;
  font-size: 100px;
  line-height: 90%; /* 90px */
  letter-spacing: -6px;
}

@mixin p0 {
  font-weight: $regular;
  font-size: 16px;
  line-height: 130%; /* 26px */
  letter-spacing: 0.16px;
}

@mixin p1 {
  font-weight: $regular;
  font-size: 14px;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.42px;
}

@mixin p2 {
  font-weight: $regular;
  font-size: 12px;
  line-height: 150%; /* 18px */
}

@mixin custom-scrollbar-0 {
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // background-color: $grey-6;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-5;
  }
}
