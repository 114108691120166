@use "@scss/variables.scss" as *;

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

hr,
a,
button,
div,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  color: $purple-1;
  background-color: $purple-10;
  font-family: "Acumin Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
section {
  height: max-content;
  width: 100%;
}

#root {
  height: 100vh;
  // min-height: 100dvh;

  > * {
    width: 100%;
  }
}

section {
  position: relative;
  // padding: 0px 16px;
}

hr {
  margin: 0px;
  padding: 0px;
  width: 100%;
  border: none;
}

a {
  color: unset;
  text-decoration: none;
  font-weight: $light;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.03em;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input,
textarea {
  border: none;
  outline: none;
}

input {
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.6px;
  font-weight: $light;
  border: none;
  outline: none;
}

#overlay {
  &-default {
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0px;
    top: 0px;
    z-index: 1000;
  }

  .drawer {
    z-index: 1001;
  }
  .dialog {
    z-index: 2001;
  }
  .modal {
    z-index: 3001;
  }
  .message {
    z-index: 4001;
  }
  .notification {
    z-index: 5001;
  }
  .alert {
    z-index: 6001;
  }
}
