@use "@scss/global.scss" as *;

.card-carousel {
  position: relative;

  .carousel-content {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: auto;
    padding: 0 $desktop-margin-x 30px;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      margin: 0 $desktop-margin-x;
      background: $purple-9;
    }

    &::-webkit-scrollbar-thumb {
      background: $purple-7;
    }
  }

  &-nav-buttons {
    position: absolute;
    right: 60px;
    top: -40px;
    display: flex;
  }

  &-button {
    padding: 4px 8px;

    svg {
      stroke: $purple-1;
      transition: stroke 0.3s ease-in-out;
    }

    &:hover {
      svg {
        stroke: $purple-3;
      }
    }

    &:active {
      svg {
        stroke: $purple-5;
      }
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  @include mq-max($md) {
    .carousel-content {
      padding: 0 $mobile-margin-x 20px;

      &::-webkit-scrollbar-track {
        margin: 0 $mobile-margin-x;
      }
    }

    &-nav-buttons {
      display: none;
    }
  }
}
