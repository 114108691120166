@use "@scss/global.scss" as *;

.categorized-girls-skeleton {
  display: flex;
  flex-flow: column nowrap;
  gap: 60px;

  .category-skeleton {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;

    .category-name-skeleton {
      position: relative;
      background-color: $purple-9;
      overflow: hidden;
      height: 26.4px;
      width: 200px;

      .skeleton-animation {
        position: absolute;
        top: 50%;
        width: 88px;
        height: 600px;
        opacity: 0.3;
        top: 50%;
        transform: rotate(15deg) translate(-180px, -50%);
        background: $gradient-1;
        animation-timing-function: cubic-bezier(0.79, 0.02, 0.24, 0.99);
        animation: gradient-animation 1s infinite;
      }
    }
  }

  @include mq-max($md) {
    .category-name {
      padding: 0 16px;
    }
  }

  @keyframes gradient-animation {
    0% {
      transform: rotate(15deg) translate(-200%, -50%);
    }
    100% {
      transform: rotate(15deg) translate(250%, -50%);
    }
  }
}
