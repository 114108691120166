@use "@scss/global.scss" as *;

.character-card-skeleton {
  position: relative;
  min-width: 288px;
  min-height: 485px;
  width: 100%;
  height: 100%;
  max-width: 288px;
  max-height: 485px;
  background-color: $purple-9;
  overflow: hidden;

  .skeleton-animation {
    position: absolute;
    top: 50%;
    width: 88px;
    height: 600px;
    opacity: 0.3;
    top: 50%;
    transform: rotate(15deg) translate(-180px, -50%);
    background: $gradient-1;
    animation-timing-function: cubic-bezier(0.79, 0.02, 0.24, 0.99);
    animation: gradient-animation 1s infinite;
  }

  @keyframes gradient-animation {
    0% {
      transform: rotate(15deg) translate(-200%, -50%);
    }
    100% {
      transform: rotate(15deg) translate(250%, -50%);
    }
  }
}
