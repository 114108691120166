@use "@scss/global.scss" as *;

.media-about-us {
  padding: 88px $desktop-margin-x;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  text-align: center;
  background-color: $purple-9;
  position: relative;

  .media-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 40px 32px;
    width: 100%;
    max-width: 1400px;
  }

  @include mq-max($md) {
    padding: 88px $mobile-margin-x;
  }

  @include mq-max($sm) {
    padding: 48px $mobile-margin-x 96px;

    .media-list {
      flex-flow: column nowrap;
      align-items: center;
      gap: 40px;
    }
  }
}
