@use "@scss/global.scss" as *;

.wallet-address {
  display: flex;
  align-items: center;
  gap: 12px;
  color: $purple-1;
  background: $purple-10;
  border-radius: 12px;
  width: max-content;
  height: 24px;
  padding: 0px 2px 0px 12px;
  cursor: pointer;

  &-addr {
    font-size: 12px;
    font-weight: $semi-bold;
    line-height: 78%; /* 9.36px */
    letter-spacing: 0.24px;
  }

  &-icon-status {
  }
}
