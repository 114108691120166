@use "@scss/global.scss" as *;

.link {
  @include no-select();
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  max-width: max-content;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
  transition: all 0.3s ease-in-out;

  svg path {
    transition: all 0.3s ease-in-out;
  }

  &-primary {
    padding: 20px;
    color: $purple-10;
    background-color: $purple-5;

    svg path {
      stroke: $purple-1;
    }

    &:hover {
      background-color: $purple-4;
    }
    &:active {
      background-color: $purple-6;
    }
    &:focus-visible {
      outline: 2px solid $purple-4;
    }
  }

  &-secondary {
    padding: 20px;
    color: $purple-1;
    background-color: transparent;
    border: 1px solid $grey-6;

    &:hover {
      color: $purple-3;

      svg path {
        stroke: $purple-3;
      }
    }
    &:active {
      border-color: transparent;
      background-color: $purple-9;
    }
    &:focus-visible {
      color: $purple-1;
      background-color: transparent;
      border: 1px solid $grey-6;
    }
  }

  &-tertiary {
    padding: 20px 0;
    color: $purple-1;
    background-color: transparent;

    &:hover {
      color: $purple-3;

      svg path {
        stroke: $purple-3;
      }
    }
    &:active {
      color: $purple-5;

      svg path {
        stroke: $purple-5;
      }
    }
    &:focus-visible {
      color: $purple-1;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
  }
}
