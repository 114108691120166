@use "@scss/global.scss" as *;

.scroll-to-button {
  display: flex;
  width: max-content;
  height: max-content;
  -webkit-tap-highlight-color: transparent;

  svg path {
    transition: stroke 0.3s ease-in-out;
  }

  &:hover,
  &:active {
    svg path {
      stroke: $purple-3;
    }
  }

  @include mq-max($md) {
    svg path {
      transition: stroke 0.1s ease-in-out;
    }

    svg {
      width: 60px;
      height: 60px;
    }
  }
}
