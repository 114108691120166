.stack {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &.full-size {
    width: 100%;
    height: 100%;
  }

  &.full-width {
    width: 100%;
  }

  &.max-content {
    width: max-content;
    height: max-content;
  }

  &.full-space {
    width: 100%;
    height: 100%;
  }
}
