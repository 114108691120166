@use "@scss/global.scss" as *;

.button {
  @include no-select();
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: max-content;
  height: 60px;
  font-family: "Acumin Pro", sans-serif;
  font-size: 14px;
  font-weight: $semi-bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;

  svg path {
    transition: all 0.3s ease-in-out;
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &-primary {
    padding: 20px;
    color: $purple-1;
    background-color: $purple-5;

    svg path {
      stroke: $purple-1;
    }

    &:hover {
      background-color: $purple-4;
    }
    &:active {
      background-color: $purple-6;
    }
    &:focus-visible {
      outline: 2px solid $purple-4;
    }
  }

  &-secondary {
    padding: 20px;
    color: $purple-1;
    background-color: transparent;
    border: 1px solid $grey-6;

    svg path {
      stroke: $purple-1;
    }

    &:hover {
      color: $purple-3;

      svg path {
        stroke: $purple-3;
      }
    }
    &:active {
      border-color: transparent;
      background-color: $purple-9;
    }
    &:focus-visible {
      color: $purple-1;
      background-color: transparent;
      border: 1px solid $grey-6;
    }
  }

  &-tertiary {
    padding: 20px 0;
    color: $purple-1;
    background-color: transparent;

    svg path {
      stroke: $purple-1;
    }

    &:hover {
      color: $purple-3;

      svg path {
        stroke: $purple-3;
      }
    }
    &:active {
      color: $purple-5;

      svg path {
        stroke: $purple-5;
      }
    }
    &:focus-visible {
      color: $purple-1;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.full-width {
    width: 100%;
    max-width: unset;
  }

  .coming-soon {
    position: absolute;
    left: 2%;
    top: 10%;
    transform: translate(-50%, -50%) rotate(-45deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 200%;
    height: 70px;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: $semi-bold;
    line-height: 150%;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }

  &-coming-soon {
    pointer-events: none;
  }

  &-primary-coming-soon {
    background: $purple-8;
    color: $purple-6;

    svg path {
      stroke: $purple-6;
    }

    .coming-soon {
      background: $purple-4;
      box-shadow: $shadow-1;
      color: $purple-8;
    }
  }

  &-secondary-coming-soon {
    color: $grey-6;
    border: 1px solid $grey-7;

    svg path {
      stroke: $grey-6;
    }

    .coming-soon {
      background: $grey-7;
      box-shadow: $shadow-1;
      color: $grey-4;
    }
  }
}
