@use "@scss/global.scss" as *;

.hero {
  position: relative;
  height: 100vh;

  &-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-content {
    position: relative;
    width: 100%;
    max-width: 1320px;
    height: 100%;
    padding: 80px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;

    .hero-title {
      @include display-2;
    }
  }

  @include mq-max($md) {
    &-content {
      padding: 40px $mobile-margin-x;
      width: 100%;

      .hero-title {
        @include h2;
      }
    }
  }
}
