@use "./mixins.scss" as *;

$primary: #b152f5;

$pink-1: #f50274;
$yellow-1: #f5b851;

$white-1: #ffffff;

$purple-1: #f7eefe;
$purple-2: #e8cbfc;
$purple-3: #e0bafb;
$purple-4: #d097f9;
$purple-5: #b152f5;
$purple-6: #6e3299;
$purple-7: #4c216b;
$purple-8: #3b1954;
$purple-9: #1a0926;
$purple-10: #09010f;

$grey-1: #f3f3f3;
$grey-2: #d1ced4;
$grey-3: #bab5be;
$grey-4: #a39da8;
$grey-5: #766b7d;
$grey-6: #483a51;
$grey-7: #3c3043;
$grey-8: #302636;
$grey-9: #1d1821;
$grey-10: #110e14;
$grey-11: #0b090d;

$success: #22b814;
$danger: #ff6b6b;
$warning: #ffdf00;
$info: #18afff;

$gradient-1: linear-gradient(90deg, rgba(26, 9, 38, 0) 0%, #3f3446 50.12%, rgba(26, 9, 38, 0) 100%);

$shadow-1: 0px 0px 29px 0px rgba(0, 0, 0, 0.19), 0px 0px 16.984px 0px rgba(0, 0, 0, 0.14),
  0px 0px 9.237px 0px rgba(0, 0, 0, 0.12), 0px 0px 4.713px 0px rgba(0, 0, 0, 0.09),
  0px 0px 2.363px 0px rgba(0, 0, 0, 0.07), 0px 0px 1.141px 0px rgba(0, 0, 0, 0.05);

// BREAKPOINTS
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

$breakpoints: (
  xs: "$xs",
  sm: "$sm",
  md: "$md",
  lg: "$lg",
  xl: "$xl",
  xxl: "$xxl"
);

// APP SIZES
$mobile: $md;
$desktop-margin-x: 60px;
$mobile-margin-x: 16px;
$app-container: 1400px;

$navbar-h: 60px;
$mobile-toolbar-h: 76px;
$sidebar-w: 80px;

// Z-INDEX'ES
$navbar-z-index: 200;
$mobile-toolbar-z-index: 100;

// SIZES
$size-variants: (
  0: 0,
  1: 2px,
  2: 4px,
  3: 8px,
  4: 16px,
  5: 24px,
  6: 32px,
  7: 40px,
  8: 48px,
  9: 64px,
  10: 80px,
  11: 96px,
  12: 160px
);

// FONT WEIGHT'S
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;
$extra-black: 950;

//POSITION VARIANTS
$position-variants: (
  start: "flex-start",
  center: "center",
  end: "flex-end"
);
