@use "@scss/global.scss" as *;

.girl-card {
  position: relative;
  max-width: 288px;
  max-height: 485px;
  display: flex;
  flex-direction: column;

  .girl-image {
    overflow: hidden;
    object-fit: cover;
    object-position: top center;
    flex: 1;
  }

  .girl-type {
    position: absolute;
    top: 12px;
    right: 16px;
  }

  .card-content {
    position: absolute;
    padding: 12px 16px 24px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    .girl-names {
      display: flex;
      flex-flow: column nowrap;

      .name {
        font-size: 24px;
        line-height: 110%; /* 26.4px */
        letter-spacing: -0.48px;
        font-weight: $semi-bold;
        @include text-ellipsis(1);
      }

      .age {
        font-size: 14px;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.42px;
        font-weight: $regular;
        @include text-ellipsis(1);
      }
    }
  }

  @include mq-max($md) {
    min-width: 100%;
    min-height: 100%;
    max-width: 343px;
    max-height: 70vh;
  }
}
