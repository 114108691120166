@use "./variables.scss" as *;
@use "./typography.scss" as *;

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

.display-1 {
  @include display-1;
}

.display-2 {
  @include display-2;
}

p,
.p0 {
  @include p0;
}

.p1 {
  @include p1;
}

.p2 {
  @include p2;
}

// Color's classes ******************************
.primary {
  color: $primary;
}
.bg-primary {
  background-color: $primary;
}

.pink-1 {
  color: $pink-1;
}
.pink-1-bg {
  background-color: $pink-1;
}
.yellow-1 {
  color: $yellow-1;
}
.yellow-1-bg {
  background-color: $yellow-1;
}

.white-1 {
  color: $white-1;
}
.white-1-bg {
  background-color: $white-1;
}

.purple-1 {
  color: $purple-1;
}
.purple-1-bg {
  background-color: $purple-1;
}
.purple-2 {
  color: $purple-2;
}
.purple-2-bg {
  background-color: $purple-2;
}
.purple-3 {
  color: $purple-3;
}
.purple-3-bg {
  background-color: $purple-3;
}
.purple-4 {
  color: $purple-4;
}
.purple-4-bg {
  background-color: $purple-4;
}
.purple-5 {
  color: $purple-5;
}
.purple-5-bg {
  background-color: $purple-5;
}
.purple-6 {
  color: $purple-6;
}
.purple-6-bg {
  background-color: $purple-6;
}
.purple-7 {
  color: $purple-7;
}
.purple-7-bg {
  background-color: $purple-7;
}
.purple-8 {
  color: $purple-8;
}
.purple-8-bg {
  background-color: $purple-8;
}
.purple-9 {
  color: $purple-9;
}
.purple-9-bg {
  background-color: $purple-9;
}
.purple-10 {
  color: $purple-10;
}
.purple-10-bg {
  background-color: $purple-10;
}

.grey-1 {
  color: $grey-1;
}
.grey-1-bg {
  background-color: $grey-1;
}
.grey-2 {
  color: $grey-2;
}
.grey-2-bg {
  background-color: $grey-2;
}
.grey-3 {
  color: $grey-3;
}
.grey-3-bg {
  background-color: $grey-3;
}
.grey-4 {
  color: $grey-4;
}
.grey-4-bg {
  background-color: $grey-4;
}
.grey-5 {
  color: $grey-5;
}
.grey-5-bg {
  background-color: $grey-5;
}
.grey-6 {
  color: $grey-6;
}
.grey-6-bg {
  background-color: $grey-6;
}
.grey-7 {
  color: $grey-7;
}
.grey-7-bg {
  background-color: $grey-7;
}
.grey-8 {
  color: $grey-8;
}
.grey-8-bg {
  background-color: $grey-8;
}
.grey-9 {
  color: $grey-9;
}
.grey-9-bg {
  background-color: $grey-9;
}
.grey-10 {
  color: $grey-10;
}
.grey-10-bg {
  background-color: $grey-10;
}
.grey-11 {
  color: $grey-11;
}
.grey-11-bg {
  background-color: $grey-11;
}

.success {
  color: $success;
}
.success-bg {
  background-color: $success;
}
.danger {
  color: $danger;
}
.danger-bg {
  background-color: $danger;
}
.warning {
  color: $warning;
}
.warning-bg {
  background-color: $warning;
}
.info {
  color: $info;
}
.info-bg {
  background-color: $info;
}

// Other's classes ******************************
.custom-scrollbar-0 {
  @include custom-scrollbar-0();
}
