@use "@scss/global.scss" as *;

.mobile-menu {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  display: flex;
  background-color: rgba($purple-10, 0.6);

  &-box {
    position: fixed;
    bottom: 0px;
    height: 75%;
    width: 100%;
    background-color: $purple-10;
    padding: 24px $mobile-margin-x;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .close-btn {
      text-transform: uppercase;
      padding: 18px 0;
      font-size: 14px;
      font-weight: $semi-bold;
      line-height: 140%; /* 19.6px */
      color: $purple-1;
      width: 100px;
      display: flex;
      align-items: center;
      transition: all 0.1s ease-in-out;

      svg path {
        transition: stroke 0.1s ease-in-out;
      }

      &:hover {
        color: $purple-3;

        svg path {
          stroke: $purple-3;
        }
      }
      &:active {
        color: $purple-5;

        svg path {
          stroke: $purple-5;
        }
      }
    }

    .mobile-menu-action {
      @include h3;

      display: flex;
      align-items: flex-end;
      gap: 12px;
      color: $white-1;
      -webkit-tap-highlight-color: transparent;
      transition: all 0.1s ease-in-out;

      svg path {
        transition: stroke 0.1s ease-in-out;
      }

      &:hover {
        color: $purple-3;

        svg path {
          stroke: $purple-3;
        }
      }
      &:active {
        color: $purple-5;

        svg path {
          stroke: $purple-5;
        }
      }
    }

    .mobile-menu-content {
      .social-links {
        margin-top: 80px;
      }
    }
  }

  @include mq-max($xs) {
    &-box {
      .mobile-menu-content {
        .social-links {
          margin-top: 40px;
        }
      }
    }
  }
}
