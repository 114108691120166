@use "@scss/global.scss" as *;

.footer {
  padding: 80px $desktop-margin-x;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 80px;

  &-row {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 65px;

    .row-column {
      display: flex;
      flex-flow: column nowrap;
      // align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      gap: 24px;

      .column-title {
        font-weight: $semi-bold;
        text-transform: uppercase;
      }
    }
  }

  .social-links {
    display: flex;
    gap: 32px;
  }

  @include mq-max($md) {
    padding: 80px $mobile-margin-x;

    &-row {
      flex-flow: column nowrap;
    }
  }
}
