@use "@scss/global.scss" as *;

.desktop-unsupported-browser {
  padding-top: 16px;
  width: 550px;

  &-modal-btn {
    min-width: 137px;
  }

  @include mq-max($md) {
    img {
      width: 50px;
      height: 50px;
    }
  }
}
