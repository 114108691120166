@use "@scss/global.scss" as *;

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding-left: 80px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid $grey-6;
  backdrop-filter: blur(18.95px);

  &-actions {
    align-items: center;
    height: 60px;

    .uniswap-link {
      &:hover {
        svg path {
          fill: $purple-3;
          stroke: none;
        }
      }

      &-icon {
        position: relative;
        top: -2px;
      }
    }
  }

  .hamburger-btn {
    display: none;
  }

  @include mq-max($md) {
    padding: 0 $mobile-margin-x;

    &-actions {
      .navbar-actions-links {
        display: none;
      }
      .navbar-actions-buttons {
      }
    }

    .hamburger-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-tap-highlight-color: transparent;

      svg path {
        transition: stroke 0.1s ease-in-out;
      }

      &:active {
        svg path {
          stroke: $purple-3;
        }
      }
    }
  }
}
